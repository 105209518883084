import React from "react"
import { Formik, Form } from "formik"

import FormInput from "../../Elements/Form/FormInput"
import FormCheckbox from "../../Elements/Form/FormCheckbox"
import ErrorMessage from "elements/ErrorMessage"

import { hasIncompleteValues } from "../services/form"
import { generateWhereDidYouHearAboutUsSummaryAnswer } from "../services/enrollment"

const WhereDidYouHearAboutUsSummary = ({ patientEnrollment }) => {
  if (
    hasIncompleteValues({
      data: patientEnrollment,
      flow: "patientEnrollmentWhereDidYouHearAboutUs",
    })
  ) {
    return (
      <ErrorMessage message="Some required information is missing or incomplete. Please double check if you have filled out the required fields." />
    )
  }

  return (
    <Formik initialValues={patientEnrollment}>
      {() => (
        <Form>
          <FormCheckbox
            name="paymentMethod"
            title={
              <p className="has-text-grey has-text-weight-normal">
                Where did you hear about the CarePlus program?
              </p>
            }
            values={generateWhereDidYouHearAboutUsSummaryAnswer({
              patientEnrollment,
            })}
            options={generateWhereDidYouHearAboutUsSummaryAnswer({
              patientEnrollment,
            })}
            disabled
            isRequired
          />
        </Form>
      )}
    </Formik>
  )
}

export default WhereDidYouHearAboutUsSummary
